(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

const localization = {// pagination: {
  //   firstTooltip: 'Primera pagina',
  //   previousTooltip: 'Anterior',
  //   nextTooltip: 'Siguiente',
  //   lastTooltip: 'Ultima pagina',
  //   labelRowsSelect: 'Registros',
  //   labelDisplayedRows: '{from}-{to} de {count}',
  // },
  // toolbar: {
  //   searchPlaceholder: 'Busqueda',
  // },
  // header: {
  //   actions: 'Opciones',
  // },
  // grouping: {
  //   groupedBy: 'Agrupado por:',
  //   placeholder: 'Arrastre aquí la columna para agruparla',
  // },
  // body: {
  //   emptyDataSourceMessage: 'Sin datos de busqueda',
  //   filterRow: {
  //     filterTooltip: 'Filtro',
  //   },
  // },
};
const options = {
  filtering: true,
  grouping: true,
  exportButton: true,
  actionsColumnIndex: -1,
  // headerStyle: { backgroundColor: 'rgb(18, 56, 133)', color: 'white' },
  pageSize: 20,
  pageSizeOptions: [20, 50, 100]
};
module.exports = {
  localization,
  options
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(localization, "localization", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/materialTableUtils.js");
  reactHotLoader.register(options, "options", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/materialTableUtils.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();