(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

const {
  default: Axios
} = require('axios');

const PUBLIC_KEY = '6Leo9N0iAAAAAL0Wda5UFgWqkpuYIldTYUwO2PQk';
const SECRET_KEY = '6Leo9N0iAAAAABnJTeJXl1ddvMMYvc5RVkBRsbok';

async function validate(response) {
  const url = `https://www.google.com/recaptcha/api/siteverify?secret=${SECRET_KEY}&response=${response}`;
  const json = await Axios.get(url); // const url = 'https://www.google.com/recaptcha/api/siteverify';
  // const json = await Axios.post(url, { secret: SECRET_KEY, response });
  // console.log('validate captcha json', json.data);
  // console.log('json.success', json.data.success);

  return json.data.success;
}

module.exports = {
  PUBLIC_KEY,
  SECRET_KEY,
  validate
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(PUBLIC_KEY, "PUBLIC_KEY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/recaptcha.js");
  reactHotLoader.register(SECRET_KEY, "SECRET_KEY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/recaptcha.js");
  reactHotLoader.register(validate, "validate", "/var/www/html/websites/thedistrictusc-com/src/server/utils/recaptcha.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();