(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

const NAME_COMPANY = 'The District';
const LICENSE_COMPANY = 'Company License';
const ADDRESS_COMPANY = 'Address';
const CITY_COMPANY = 'City';
const STATE_COMPANY = 'State';
const ZIP_CODE_COMPANY = 'Zip';
const PHONE_COMPANY = '8183054849';
const WHATSAPP_LINK = 'https://wa.link/b0ived';
const EMAIL_COMPANY = 'leasing@livewithmosaic.com';
const EMAIL_CONTACT = 'leasing@livewithmosaic.com';
const DOMAIN_WEBSITE = 'https://website.com';
const IP_SERVER = '8.8.8.8';

const recaptcha = require('./recaptcha');

function formatPhoneText(text) {
  let regexObj = /^(?:\+?1[-. ]?)?\(?([0-9]{3})\)?$/;
  let result = '';

  if (regexObj.test(text)) {
    result = text.replace(regexObj, '($1)');
  } else {
    regexObj = /^(?:\+?1[-. ]?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})?$/;

    if (regexObj.test(text)) {
      result = text.replace(regexObj, '($1) $2-$3');
    }
  }

  return result;
}

function formatDateTime(date) {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
    timeZone: 'America/Los_Angeles'
  };
  console.log('date', date);
  let finalDate = date;

  if (!(finalDate instanceof Date)) {
    finalDate = new Date(date);
  }

  console.log('finalDate', finalDate);
  return new Intl.DateTimeFormat('en-US', options).format(finalDate);
}

module.exports = {
  ADDRESS_COMPANY,
  NAME_COMPANY,
  STATE_COMPANY,
  CITY_COMPANY,
  ZIP_CODE_COMPANY,
  EMAIL_COMPANY,
  PHONE_COMPANY,
  EMAIL_CONTACT,
  DOMAIN_WEBSITE,
  WHATSAPP_LINK,
  LICENSE_COMPANY,
  IP_SERVER,
  formatPhoneText,
  recaptcha,
  formatDateTime
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(NAME_COMPANY, "NAME_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(LICENSE_COMPANY, "LICENSE_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(ADDRESS_COMPANY, "ADDRESS_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(CITY_COMPANY, "CITY_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(STATE_COMPANY, "STATE_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(ZIP_CODE_COMPANY, "ZIP_CODE_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(PHONE_COMPANY, "PHONE_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(WHATSAPP_LINK, "WHATSAPP_LINK", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(EMAIL_COMPANY, "EMAIL_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(EMAIL_CONTACT, "EMAIL_CONTACT", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(DOMAIN_WEBSITE, "DOMAIN_WEBSITE", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(IP_SERVER, "IP_SERVER", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(formatPhoneText, "formatPhoneText", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
  reactHotLoader.register(formatDateTime, "formatDateTime", "/var/www/html/websites/thedistrictusc-com/src/server/utils/utilities.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();