(function () {
  var enterModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.enterModule : undefined;
  enterModule && enterModule(module);
})();

var __signature__ = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default.signature : function (a) {
  return a;
};

/* eslint-disable no-restricted-globals */
const qs = require('qs');

const utilities = require('../../server/utils/utilities');

const materialTable = require('./materialTableUtils');

const NEXT_PUBLIC_ADDRESS_COMPANY = '1144-1150 W. 28th Street';
const NEXT_PUBLIC_CITY_COMPANY = 'Los Angeles';
const NEXT_PUBLIC_ZIPCODE_COMPANY = 'CA 90007';
const NEXT_PUBLIC_PHONE_COMPANY = '(323) 733-2258';

function questionDelete() {
  return confirm("Are you sure you want to delete this record?\nThis process can't be reversed.");
}

function getQuery(location) {
  return qs.parse(location.search, {
    ignoreQueryPrefix: true
  });
}

module.exports = { ...utilities,
  questionDelete,
  getQuery,
  materialTable,
  NEXT_PUBLIC_ADDRESS_COMPANY,
  NEXT_PUBLIC_PHONE_COMPANY,
  NEXT_PUBLIC_CITY_COMPANY,
  NEXT_PUBLIC_ZIPCODE_COMPANY
};
;

(function () {
  var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.default : undefined;

  if (!reactHotLoader) {
    return;
  }

  reactHotLoader.register(NEXT_PUBLIC_ADDRESS_COMPANY, "NEXT_PUBLIC_ADDRESS_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/utilities.js");
  reactHotLoader.register(NEXT_PUBLIC_CITY_COMPANY, "NEXT_PUBLIC_CITY_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/utilities.js");
  reactHotLoader.register(NEXT_PUBLIC_ZIPCODE_COMPANY, "NEXT_PUBLIC_ZIPCODE_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/utilities.js");
  reactHotLoader.register(NEXT_PUBLIC_PHONE_COMPANY, "NEXT_PUBLIC_PHONE_COMPANY", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/utilities.js");
  reactHotLoader.register(questionDelete, "questionDelete", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/utilities.js");
  reactHotLoader.register(getQuery, "getQuery", "/var/www/html/websites/thedistrictusc-com/src/frontend/utils/utilities.js");
})();

;

(function () {
  var leaveModule = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal.leaveModule : undefined;
  leaveModule && leaveModule(module);
})();